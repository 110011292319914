import { useState, useEffect } from 'react'

// Hook for media queries
// const isTablet = useMedia('(max-width: 960px)')

function useMedia(query) {
  const isClient = typeof window === 'object'
  const [matches, setMatches] = useState(
    isClient && window.matchMedia(query).matches
  )
  useEffect(() => {
    if (!isClient) {
      return
    }

    const media = window.matchMedia(query)
    if (media.matches !== matches) {
      setMatches(media.matches)
    }

    const listener = () => setMatches(media.matches)
    media.addListener(listener)

    return () => media.removeListener(listener)
  }, [matches, query, isClient])

  return matches
}

export default useMedia
