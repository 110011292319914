import React from 'react'
import { node, string } from 'prop-types'

const AccentColorContext = React.createContext()

const AccentColorContextProvider = ({ children, color }) => (
  <AccentColorContext.Provider value={color}>
    {children}
  </AccentColorContext.Provider>
)

AccentColorContextProvider.propTypes = {
  children: node.isRequired,
  color: string
}

const AccentColorContextConsumer = AccentColorContext.Consumer

export {
  AccentColorContext,
  AccentColorContextProvider,
  AccentColorContextConsumer
}
