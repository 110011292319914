// NEVER PUT SECRETS IN THIS FILE

const config = {
  defaults: {
    sentryDsn:
      'https://db3c6c1ce01a43ae961d27cdd2bd25c9@o36550.ingest.sentry.io/5377168',
    reCaptchaSiteKey: '6LcGJ2gcAAAAAJNM5nhP2O0WIzdQ7XPhzwQ7yRr1',
    gtag: '',
    meta: {
      titleTemplate: `%s | Nessy`,
      description: `Learning happens when it's fun`,
      twitter: {
        cardType: 'summary_large_image',
        site: '@nessylearning'
      },
      googleSiteVerification: null,
      locale: 'en_GB',
      themeColor: '#3bae70',
      title: 'Nessy',
      twitterHandle: '@nessylearning'
    },
    cdnDomain: ''
  },
  production: {
    gtag: 'G-RK6M1RM449',
    cdnDomain: 'https://website-nessycdn.com'
  }
}

const envConfig = () => {
  const env = process.env.NODE_ENV
  return { ...config.defaults, ...config[env], env }
}

export default envConfig
